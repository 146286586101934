const product1 = {
  title: 'Nanny',
  size: '12" by 12" ',
  imageArray: ['/images/custom/Nanny/custom-1.webp', '/images/custom/Nanny/custom-1.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: 'Unavailable',
  name: 'Nanny',
  folder: 'Nanny',
};

const product2 = {
  title: 'AJ',
  size: '12" by 12" ',
  imageArray: ['/images/custom/AJ/AJ.webp', '/images/custom/AJ/AJ.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: 'Unavailable',
  name: 'AJ',
  folder: 'AJ',
};

const product3 = {
  title: 'Izzy',
  size: '12" by 12" ',
  imageArray: ['/images/custom/Izzy/Izzy.webp', '/images/custom/Izzy/Izzy.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: 'Unavailable',
  name: 'Izzy',
  folder: 'Izzy',
};

const product4 = {
  title: 'Bella',
  size: '12" by 12" ',
  imageArray: ['/images/custom/Bella/Bella-1.webp', '/images/Bella/Bella/custom-1.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: 'Unavailable',
  name: 'Bella',
  folder: 'Bella',
};

const productList = [product1, product2, product3, product4];

export default productList;
