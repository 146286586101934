const product1 = {
  title: 'Detail-1',
  size: '12" by 12" ',
  imageArray: ['/images/custom/Nanny/detail-1.webp', '/images/custom/Nanny/detail-1.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: 'Unavailable',
  hoverEnabled: true, // Hover effect enabled
};

const product2 = {
  title: 'Original-1',
  size: '12" by 12" ',
  imageArray: ['/images/custom/Nanny/original-1.webp', '/images/custom/Nanny/original-1.webp'],
  description: 'Mixed Media',
  status: 'Sold',
  price: 'Unavailable',
  hoverEnabled: true, // Hover effect enabled
};

const productList = [product1, product2];

export default productList;
